import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';

const {
  assets,
  auth,
  balance,
  identity,
  plaidLink,
  paymentInitiation,
  transactions,
} = {
  assets: {
    name: 'Assets',
    description: "Point-in-time snapshots of users' finances",
    url: '/en-eu/products/assets',
    icon: '/assets/img/navbar/icon-assets-global.png',
  },
  auth: {
    name: 'Auth',
    description: 'Account number, sort code, IBAN, and BIC',
    url: '/en-eu/products/auth',
    icon: '/assets/img/navbar/icon-auth.png',
  },
  balance: {
    name: 'Balance',
    description: 'Real-time balance checks',
    url: '/en-eu/products/balance',
    icon: '/assets/img/navbar/icon-balance.png',
  },
  identity: {
    name: 'Identity',
    description: 'Bank account-holder information',
    url: '/en-eu/products/identity',
    icon: '/assets/img/navbar/icon-identity.png',
  },
  plaidLink: {
    name: 'Plaid Link',
    description:
      'Enable your users to instantly connect their financial accounts',
    url: '/en-eu/plaid-link',
    icon: '/assets/img/navbar/icon-plaid-link.png',
  },
  paymentInitiation: {
    name: 'Payment Initiation',
    description: 'Instant bank payments within your app',
    url: '/en-eu/products/payment-initiation',
    icon: '/assets/img/navbar/icon-payment-initiation.png',
  },
  transactions: {
    name: 'Transactions',
    description: '5+ years of categorised transaction data',
    url: '/en-eu/products/transactions',
    icon: '/assets/img/navbar/icon-transactions-global.png',
  },
};

const columns = [
  {
    gridClasses: 'small-4',
    items: [paymentInitiation, balance, identity],
  },
  {
    gridClasses: 'small-4',
    items: [transactions, assets, auth],
  },
  {
    gridClasses: 'small-4',
    items: [plaidLink],
  },
];

const products = {
  label: 'Products',
  megaMenuClass: 'products',
  submenuClass: 'cell small-9',
  subtitle: 'Learn how you can make the most of financial data',
  menuLinks: [
    {
      name: 'Global coverage',
      url: '/global',
    },
    {
      name: 'Pricing',
      url: '/pricing',
      trackingId: NAVIGATION_EVENTS.PRIMARY.USER_CLICKED_PRIMARY_NAV_PRICING,
    },
  ],
  submenu: {
    columns,
  },
};

export default products;
