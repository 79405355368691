import React from 'react';
import { useRouter } from 'next/router';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { getCookie } from 'public/assets/js/analytics/cookie';
import {
  normalizeContentSettings,
  conditionallyInitializeAnalytics,
  conditionallyLoadOptimizely,
  setUserTraits,
  hideDocsCookieButton,
} from 'src/containers/ConsentManager/utils';

declare global {
  interface Window {
    OneTrust: any;
    OnetrustActiveGroups: string;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useOneTrust = () => {
  const { route } = useRouter();
  const track = useTrack();
  const [consentSettings, setTrackingConsent] = React.useState('');
  const [isOneTrustLoaded, setIsOneTrustLoaded] = React.useState(false);
  const [isAnalyticsLoaded, setIsAnalyticsLoaded] = React.useState(false);
  const [isSegmentInitialized, setIsSegmentInitialized] = React.useState(false);
  const [isOptimizelyLoaded, setIsOptimizelyLoaded] = React.useState(false);

  React.useEffect(() => {
    const maybeOneTrust = setInterval(() => {
      if (!!window.OneTrust) {
        setIsOneTrustLoaded(!!window.OneTrust);
        clearInterval(maybeOneTrust);
      }
    }, 100);

    if (!isOneTrustLoaded) {
      return;
    }

    const maybeAnalytics = setInterval(() => {
      if (!!window.analytics) {
        setIsAnalyticsLoaded(!!window.analytics);
        clearInterval(maybeAnalytics);
      }
    }, 100);

    if (!isAnalyticsLoaded) {
      return;
    }

    // we remove the floating cookie from docs to not obstruct the sidebar view
    hideDocsCookieButton(route);

    // sets initial state to current consent preferences
    setTrackingConsent(window?.OnetrustActiveGroups);

    // we conditionally initialize analytics if any preferences are selected
    if (!isSegmentInitialized) {
      setIsSegmentInitialized(true);
      conditionallyInitializeAnalytics(
        normalizeContentSettings(window?.OnetrustActiveGroups),
      );
    }

    // we conditionally load optimizely if any preferences are selected
    if (!isOptimizelyLoaded) {
      setIsOptimizelyLoaded(true);
      conditionallyLoadOptimizely(
        normalizeContentSettings(window?.OnetrustActiveGroups),
      );
    }

    if (!getCookie('OptanonAlertBoxClosed')) {
      track({
        type: 'CONSENT_BANNER_VIEW',
        payload: {
          nonInteraction: 1,
        },
      });
    }

    // sets consent settings on consent change.
    window.OneTrust.OnConsentChanged(function () {
      setTrackingConsent(window.OnetrustActiveGroups);

      // track when the user consents to everything
      if (normalizeContentSettings(window.OnetrustActiveGroups).length === 4) {
        track({ type: 'CONSENT_BANNER_ACCEPT_ALL' });
      }

      // TODO: there is a bug when analytics are NOT loaded by default and consent changes to allow
      // tracking there is a condition where a track event does not happen because analytics have not yet loaded

      // we set user traits so we can access them on the
      // server-side and from destinations (warehouse) https://github.com/segmentio/consent-manager#segment-consent-manager
      setUserTraits(normalizeContentSettings(window?.OnetrustActiveGroups));

      // we reload the page so that script elements are added and removed
      setTimeout(() => {
        window.location.reload();
      }, 0);

      return () => {
        clearInterval(maybeOneTrust);
        clearInterval(maybeAnalytics);
      };
    });
  }, [
    consentSettings,
    isAnalyticsLoaded,
    isOneTrustLoaded,
    isOptimizelyLoaded,
    isSegmentInitialized,
    route,
    track,
  ]);

  return { consentSettings };
};

export default useOneTrust;
