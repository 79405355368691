export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      'meta-title': 'Plaid Check Consumer Request Form',
      'meta-description': 'Submit a request about your Consumer Report',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Plaid Consumer Reporting Agency Request Form',
    introHeader: 'Introduction',
    intro: `<p>To submit a request, you are required to provide all the information below. Please fill out the form with the same information you previously shared with the company that requested your consumer report.</p>

<p>The information that you provide will only be used by Plaid Check to verify your identity and to process your request. We may not be able to comply with your request if we are unable to confirm your identity or connect the information you submit in your request with personal information in our possession.</p>

<p>Please complete all fields on the form so that we may properly process your request. If we are unable to process your request, we will notify you via the email you provide.</p>

<p>To contact Plaid Inc., please visit the <a href="https://support-my.plaid.com/hc/en-us">Plaid Inc. Consumer Help Center</a>.</p>`,
    formHeader: 'Form',
    firstName: {
      label: 'First name',
    },
    lastName: {
      label: 'Last name',
    },
    email: {
      label: 'Email',
      error: 'Invalid email address',
    },
    phone: {
      label: 'Phone',
      error: 'Invalid phone number',
    },
    streetAddress: {
      label: 'Street address',
    },
    city: {
      label: 'City',
    },
    stateOrRegion: {
      label: 'State or region',
      error: 'Invalid 2-letter state/region',
    },
    zipCode: {
      label: 'ZIP code',
      error: 'Invalid ZIP code',
    },
    country: {
      label: 'Country',
      error: 'Country must be US',
    },
    location: {
      label: 'Location',
      locations: [
        {
          label: 'United States - California',
          value: 'United States - California',
        },
        {
          label: 'United States - Not in California',
          value: 'United States - Not in California',
        },
        {
          label: 'United Kingdom',
          value: 'United Kingdom',
        },
        {
          label: 'European Economic Area (EEA)',
          value: 'European Economic Area (EEA)',
        },
        {
          label: 'Canada',
          value: 'Canada',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    },
    accountMask: {
      label: 'Last 4 digits of account number',
    },
    accountUsername: {
      label: 'Account username or login number (optional)',
    },
    accountNumber: {
      label: 'Account number (optional)',
    },
    applicationsConnected: {
      label: 'Apps or services connected via Plaid (optional)',
    },
    paymentInitiation: {
      label: "I've used Plaid to initiate payments from my account(s)",
    },
    requestType: {
      label: 'Select request type',
      options: [
        {
          label: 'Request a copy of my Consumer Report',
          value: 'Request Copy',
        },
        {
          label: 'Submit a dispute',
          value: 'Dispute',
        },
        {
          label: 'Disconnect the specified connection(s)',
          value: 'Disconnect',
        },
        {
          label: 'Other questions or concerns',
          value: 'Other',
        },
      ],
    },
    financialInstitutions: {
      label: 'Search and select financial institutions',
    },
    cannotFindFinancialInstitutions:
      'My financial institution(s) are not included in search results.',
    otherFIs: {
      label: 'Other financial institutions',
    },
    fiSearchLoading: 'Searching institutions...',
    fiSearchNoneFound: 'No institutions found',
    fiSearchInstruction:
      'Start typing to search for your financial institution(s)',
    institutionSearchAndSelectDisclaimer: {
      label:
        'Please provide the name of the bank or financial institution for the account that you connected to Plaid Check.',
    },
    supportingInformation: 'Supporting information',
    appsAndServices: {
      label: 'Apps & Services',
      placeholder:
        'Please list any apps or services you’ve connected using Plaid (optional)',
    },
    requestDetails: {
      label: 'Additional information (optional)',
    },
    submitText: 'Submit',
    formSuccessMessage:
      'We’ve received your request. Please refresh this page if you would like to submit another request using this form.',
    formErrorMessage:
      'There was an error with sending your request. Please try again or contact us directly at ',
    disclaimerText:
      'By submitting this form, I certify that I am the consumer identified in this form and the information I have provided is accurate. Further, I acknowledge that I have consented to electronic disclosure of this information by way of my Consumer Services Agreement.',
    additionalInfoHeader: 'Plaid Check Legal Information',
    additionalInfoBullets: [
      [
        '/plaid-check-consumer-report/notices-of-consumer-rights',
        'Federal and State Notices of Consumer Rights',
      ],
      ['/plaid-check-consumer-report/privacy-policy', 'Privacy Policy'],
      [
        '/plaid-check-consumer-report/consumer-services-agreement',
        'Consumer Services Agreement',
      ],
    ],
  },
};
