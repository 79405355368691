export const NAVIGATION_EVENTS = {
  PRIMARY: {
    USER_CLICKED_PRIMARY_NAV_PRICING: 'USER_CLICKED_PRIMARY_NAV_PRICING',
    GET_API_KEYS_BUTTON: 'HOMEPAGE_MAIN_NAV_GET_API_KEYS_BUTTON',
  },
  FOOTER: {
    USER_CLICKED_FOOTER_NAV_PRICING: 'USER_CLICKED_FOOTER_NAV_PRICING',
    GET_STARTED_BUTTON: 'FOOTER_GET_STARTED_BUTTON',
    CONTACT_SALES_BUTTON: 'FOOTER_CONTACT_SALES_BUTTON',
  },
};

export const HOMEPAGE_EVENTS = {
  GET_API_KEYS: {
    HERO: 'HOMEPAGE_HERO_GET_API_KEYS_BUTTON',
    FOOTER: 'HOMEPAGE_FOOTER_GET_API_KEYS_BUTTON',
  },
  CONTACT_SALES: {
    HERO: 'HOMEPAGE_HERO_CONTACT_SALES_LINK',
    FOOTER: 'HOMEPAGE_FOOTER_CONTACT_SALES_BUTTON',
  },
};

export const PRICING_EVENTS = {
  PRICING_GET_API_KEYS_BUTTON_CLICK: 'PRICING_GET_API_KEYS_BUTTON_CLICK',
  PRICING_REQUEST_ACCESS_BUTTON_CLICK: 'PRICING_REQUEST_ACCESS_BUTTON_CLICK',
  PRICING_CONTACT_SALES_BUTTON_CLICK: 'PRICING_CONTACT_SALES_BUTTON_CLICK',
  PRICING_ESUSU_CASE_STUDY_LINK: 'PRICING_ESUSU_CASE_STUDY_LINK',
  PRICING_SHIFT_CASE_STUDY_LINK: 'PRICING_SHIFT_CASE_STUDY_LINK',
  PRICING_ZETA_CASE_STUDY_LINK: 'PRICING_ZETA_CASE_STUDY_LINK',
  PRICING_FAQ_CONTACT_SALES_LINK: 'PRICING_FAQ_CONTACT_SALES_LINK',
  PRICING_FAQ_PLAID_BILLING_LINK: 'PRICING_FAQ_PLAID_BILLING_LINK',
};

export const CUSTOMER_STORIES_EVENTS = {
  USER_TOGGLED_CUSTOMER_STORIES_FILTER: 'USER_TOGGLED_CUSTOMER_STORIES_FILTER',
  USER_CLICKED_CUSTOMER_STORIES_DOWNLOAD_PDF:
    'USER_CLICKED_CUSTOMER_STORIES_DOWNLOAD_PDF',
};

export const PARTNERSHIP_DIRECTORY_EVENTS = {
  PARTNER_CLICK: 'PARTNER_CLICK',
  INDUSTRY_SEARCH: 'INDUSTRY_SEARCH',
};

export const EMBEDDED_VIDEO_EVENTS = {
  ON_PLAY: 'ON_PLAY',
  ON_PAUSE: 'ON_PAUSE',
  ON_SEEK: 'ON_SEEK',
  ON_ENDED: 'ON_ENDED',
  ON_ERROR: 'ON_ERROR',
};

export const TUTORIAL_EVENTS = {
  TUTORIAL_PROJECT_RESET: 'TUTORIAL_PROJECT_RESET',
  TUTORIAL_STEP_VIEWED: 'TUTORIAL_STEP_VIEWED',
  TUTORIAL_STARTED: 'TUTORIAL_STARTED',
  TUTORIAL_PROJECT_SAVED: 'TUTORIAL_PROJECT_SAVED',
  TUTORIAL_FEEDBACK: 'TUTORIAL_FEEDBACK',
};

export const CHATBOT_EVENTS = {
  CHAT_SEARCH_TAB_SELECTED: 'CHAT_SEARCH_TAB_SELECTED',
  QUESTION_ASKED: 'CHATBOT_QUESTION_ASKED',
  FEEDBACK_GIVEN: 'CHATBOT_FEEDBACK_GIVEN',
  FIRST_RESPONSE_RECEIVED: 'CHATBOT_FIRST_RESPONSE_RECEIVED',
  SOURCES_RECEIVED: 'CHATBOT_SOURCES_RECEIVED',
  QUESTION_FINISHED: 'CHATBOT_QUESTION_FINISHED',
  USER_CLICKED_STOP: 'CHATBOT_USER_CLICKED_STOP',
  ERROR_RECEIVED: 'CHATBOT_ERROR_RECEIVED',
};

export const LINK_TOKEN_CREATE_EVENTS = {
  SELECTOR_LOADED: 'LINK_TOKEN_CREATE_SELECTOR_LOADED',
  OPTION_PICKED: 'LINK_TOKEN_CREATE_OPTION_PICKED',
};

export const PAGE = {
  CUSTOMER_STORY: 'CUSTOMER_STORY',
  LANDING_PAGE: 'LANDING_PAGE',
  CAREERS: 'CAREERS',
  EVENT: 'EVENT',
  INSTITUTION: 'INSTITUTION',
  PARTNER: 'PARTNER',
  PRODUCT: 'PRODUCT',
  USE_CASE: 'USE_CASE',
  ARTICLE: 'ARTICLE',
  BLOG: 'BLOG',
  THREADS_EVENT_23: 'THREADS_EVENT_23',
};

export const LOGGED_IN_DOCS = {
  USER_CLICKED_LOGGED_IN_DOCS_SIGN_IN: 'USER_CLICKED_LOGGED_IN_DOCS_SIGN_IN',
  USER_CHANGED_SETTINGS: 'USER_CHANGED_SETTINGS',
  USER_CLICKED_ON_MENU: 'USER_CLICKED_ON_MENU',
};
