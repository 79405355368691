import parsePath from './parse-path';

// docs helpers
import { isExternalUrl } from 'src/components/docs/utilities';

// constants
import { LocaleMap, Locales } from 'src/lib/constants';

// Gets the last segment of a route ie: /products/transactions -> transactions
export const getCurrentPage = (route = ''): string => {
  const path = route.split('/').filter((segment) => {
    return segment !== '';
  });
  return path[path.length - 1];
};

export const isStaticAsset = (url: string): boolean => {
  return (
    url.match(
      /\.(js|map|woff2|ttf|svg|webmanifest|webm|mp4|jpg|jpeg|png|webp|css|pdf|zip|txt|json|xml|gif|bmp|exe|wav|tiff|rar|ram|gif|doc|avi|woff)/,
    )?.length > 0
  );
};

export const appendTrailingSlash = (url = ''): string => {
  if (isStaticAsset(url)) return url;
  const { resource, hash, search } = parsePath(url);
  if (url === '#') return url;
  if (
    (resource && !resource.includes('plaid.com')) ||
    (resource && !/(\/\/|www.)plaid.com/.test(url))
  ) {
    return url;
  }
  if (search) return url.replace('?' + search, '/?' + search);
  if (hash) return url;
  if (!url.endsWith('/')) return `${url}/`;
  return url;
};

export const formatUrl = (url: string): string => {
  try {
    const urlObj = new URL(url, 'https://plaid.com');
    return urlObj.href;
  } catch (e) {
    // this code remains for backwards compatiability until it's no longer needed
    if (url) {
      const startsWithHash = url.startsWith('#');
      if (startsWithHash) {
        return url;
      }
      const urlContainsHttp = url.includes('http');
      const urlContainsPeriod = url.includes('.');
      const startsWithSlash = url.startsWith('/');
      return startsWithSlash || !urlContainsPeriod || urlContainsHttp
        ? prependLeadingSlash(url)
        : `//${url}`;
    }
    return '';
  }
};

const HREF_REGEX = /href=("|')(.+?)("|')/g;

export const replaceHrefValueWithLocale = ({
  locale,
  str,
}: {
  locale: string;
  str: string;
}): string => {
  return str.replace(HREF_REGEX, (match, p1, p2) => {
    return `href=\"${prependLocale({ href: p2, locale })}\"`;
  });
};

export const mutateHardCodedLinks = ({ data, locale }) => {
  // handle null and undefined
  if (!data) {
    return null;
  }

  Object.entries(data).forEach(([k, v]) => {
    // handle strings
    if (typeof v === 'string') {
      if (HREF_REGEX.test(v)) {
        data[k] = replaceHrefValueWithLocale({ locale, str: v });
      }
    }
    // handle objects and arrays
    if (typeof v === 'object') {
      // recurse
      mutateHardCodedLinks({ data: v, locale });
    }
  });

  return data;
};

export const prependLeadingSlash = (url: string): string => {
  const containsHttp = url.includes('http');
  return containsHttp || url.startsWith('/') ? url : `/${url}`;
};

export const isAbsolutePlaidUrl = (url: string): boolean => {
  return new RegExp('(www.|//|demo.|blog.)plaid.com').test(url);
};

export const stripLeadingSlash = (str: string): string => {
  if (str.startsWith('/')) {
    return str.substring(1);
  }
  return str;
};

export const isAnchor = (href: string): boolean => {
  return href.includes('#');
};

export const isLegacyLocaleLink = (href: string): boolean => {
  const legacyLocalePattern = Object.keys(LocaleMap).join('|');
  const hasLegacyLocale = new RegExp(`\/(${legacyLocalePattern})\/`, 'g').test(
    href,
  );
  return hasLegacyLocale;
};

export const hasLocale = (href: string): boolean => {
  const hrefContainsLocale = Object.values(Locales).some((locale) => {
    return href.includes(locale);
  });
  return hrefContainsLocale;
};

export const prependLocale = ({
  locale,
  href = '',
}: {
  locale?: string;
  href?: string;
}): string => {
  if (!locale || locale === Locales.EN_US) return href;
  // starts with plaid.com
  if (isAbsolutePlaidUrl(href) && !isStaticAsset(href)) {
    const split = href.split('.com');
    if (isAnchor(split[1])) {
      // we don't want the trailing slash here or it will cause the browser anchor to not work
      return prependLeadingSlash(locale + split[1]);
    }
    const path = appendTrailingSlash(prependLeadingSlash(split[1]));
    return `/${locale}${path}`;
  }
  if (isExternalUrl(href)) return href;
  if (isStaticAsset(href)) return href;
  if (isLegacyLocaleLink(href)) return href;
  if (hasLocale(href)) return href;
  if (isAnchor(href)) {
    if (stripLeadingSlash(href).startsWith('#')) return href;
    return prependLeadingSlash(locale + href);
  }
  return `/${locale}${appendTrailingSlash(prependLeadingSlash(href))}`;
};

export const prependStagingHash = (href = '', basePath: string): string => {
  if (!basePath || !href || href.includes(basePath)) return href;
  if (isExternalUrl(href)) return href;
  if (isAnchor(href)) {
    if (href.startsWith('#')) return href;
  }
  const normalizedHref = href.startsWith('/') ? href : `/${href}`;
  return `${basePath}${normalizedHref}`;
};

export const makeLocalizedUrl = ({
  domain,
  userLocale,
  pageLocale,
  path,
  basePath,
}: {
  domain: string;
  userLocale?: string;
  pageLocale: string;
  path: string;
  basePath: string;
}): string => {
  const parsedPath = parsePath(path);
  const pageSegment = appendTrailingSlash(
    parsedPath.pathname.replace(`/${pageLocale}`, ''),
  );
  if (!userLocale || userLocale === Locales.EN_US) {
    return `${domain}${basePath ? `${basePath}` : ''}${pageSegment}`;
  }
  return `${domain}${
    basePath ? `${basePath}/` : '/'
  }${userLocale}${pageSegment}`;
};

export const getLocalizedFallbackUrl = (
  userLocale: string,
  basePath?: string,
): string => {
  if (userLocale === Locales.EN_US) {
    return `${basePath ? `/${basePath}` : ''}/`;
  }
  return `${basePath ? `${basePath}` : ''}/${userLocale}/`;
};

export const fetchLocalizedEquivalentPage = async ({
  asPath,
  userLocale,
  pageLocale,
  basePath,
}) => {
  const DOMAIN = window.location.origin;
  try {
    const fetchUrl = makeLocalizedUrl({
      domain: DOMAIN,
      path: asPath,
      userLocale,
      pageLocale,
      basePath: basePath,
    });
    const response = await fetch(fetchUrl);
    if (response.status === 200) {
      // if the localized equivalent exists then dispatch payload so the toast
      // links the user to the correct page
      return fetchUrl.replace(DOMAIN, '');
    } else {
      // if no localized equivalent exists then link the user to the homepage
      // for their locale
      return getLocalizedFallbackUrl(userLocale, basePath);
    }
  } catch (error) {
    // something went wrong, let's link them to the homepage for their locale
    return getLocalizedFallbackUrl(userLocale, basePath);
  }
};

export const isPdfUrl = (url: string): boolean => {
  return url.split('?')[0].endsWith('.pdf');
};

export const getUrlDomain = (url) => {
  const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
  return matches && matches[1].replace('www.', '');
};

export const sanitizeVideoUrl = (url) => {
  // daily motion fix (cannot include '?playlist=...')
  if (getUrlDomain(url) === 'dailymotion.com') {
    return url.indexOf('?') > 0 ? url.slice(0, url.indexOf('?')) : url;
  } else {
    return url;
  }
};

export const mapSearchParamsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

export const appendSuccessParam = (url, marketoKey) => {
  const queryString = 'form=' + marketoKey;
  try {
    const urlObj = new URL(url, 'https://plaid.com');
    if (urlObj.search) {
      urlObj.search += '&' + queryString;
    } else {
      urlObj.search += '?' + queryString;
    }
    return urlObj.href;
  } catch (e) {
    // try to handle invalid URLs
    if (url.includes('?')) {
      return url + '&' + queryString;
    } else {
      return url + '?' + queryString;
    }
  }
};
