import { PRICING_EVENTS } from 'src/lib/trackingEvents.ts';

export const metaData = {
  'meta-title': 'Pricing - United States & Canada',
  'meta-description':
    'Build with our free tier; pay as you go; or scale with tailored API solutions and volume pricing. Your first 200 API calls are free.',
};

const freeTierTableData = {
  img: '/assets/img/icons/pricing-test-plant.png',
  imageLabel: 'smallPlant',
  label: 'Test for Free',
  heading: 'Free',
  description: 'Build and test using our core set of products with live data',
  infoId: 'free',
  ctaLink: '//dashboard.plaid.com/signup',
  ctaText: 'Get started',
  buttonTrackingId: PRICING_EVENTS.PRICING_GET_API_KEYS_BUTTON_CLICK,
  hideServicesMobile: true,
  services: {
    title: 'Free access includes:',
    items: [
      'Unlimited Sandbox test API calls',
      '200 live API calls per product',
    ],
  },
};

const payGTierTableData = {
  img: '/assets/img/icons/pricing-launch-plant.png',
  imageLabel: 'mediumPlant',
  label: 'No Monthly Minimum',
  heading: 'Pay as you go',
  description: 'Launch your project with no contractual minimums',
  ctaLink: '//dashboard.plaid.com/signup',
  ctaText: 'Request access',
  buttonTrackingId: PRICING_EVENTS.PRICING_REQUEST_ACCESS_BUTTON_CLICK,
  hideServicesMobile: true,
  services: {
    title: 'Everything in Free, plus:',
    items: ['Unlimited live API calls', 'Standard pricing'],
  },
};

const customTierTableData = {
  img: '/assets/img/icons/pricing-scale-plant.png',
  imageLabel: 'largePlant',
  label: 'Volume pricing discounts',
  heading: 'Custom plan',
  description:
    'Get tailored solutions, volume pricing, and dedicated support for your team',
  ctaLink: '//dashboard.plaid.com/contact',
  ctaText: 'Contact sales',
  buttonTrackingId: PRICING_EVENTS.PRICING_CONTACT_SALES_BUTTON_CLICK,
  services: {
    title: 'Everything in Pay as you go, plus:',
    items: ['Starts at $500/month', 'Account management', 'Beta products'],
    hasAddOns: true,
    addOns: ['Integration assistance', 'Premium support'],
  },
};

export const pricingHeaderTableData = {
  title: 'Pricing - United States & Canada',
  header: 'Plans that scale with your growth',
  tableData: [freeTierTableData, payGTierTableData, customTierTableData],
};

export const socialProofData = {
  'is-light': true,
  header: 'Leading Companies Choose Plaid',
  alignment: 'center',
  'column-count': 4,
  'full-width': true,
  logos: [
    {
      img: '/assets/img/logos/logo-venmo-black600.png',
      alt: 'Venmo',
    },
    {
      img: '/assets/img/logos/logo-betterment-black600.png',
      alt: 'Betterment',
    },
    {
      img: '/assets/img/logos/logo-rocket-money-black600@2x.png',
      alt: 'Rocket Money',
    },
    {
      img: '/assets/img/logos/logo-acorns-black600.png',
      alt: 'Acorns',
    },
  ],
};

export const credibilityData = {
  carousel: true,
  items: [
    {
      type: 'quote-with-stat',
      'image-src-medium-up':
        '/assets/img/products/standalone/auth/credibility-auth-iphone-shift@2x.png',
      'bg-color': 'bg-green',
      'quote-text':
        'Plaid enabled Shift to offer a fully integrated online purchasing experience. The financial aspects of the process are much more seamless',
      'quote-byline': 'Jason Wu, Software Engineer at Shift',
      'cta-text': 'View case study',
      'cta-link': '/documents/Shift-Case-Study.pdf',
      trackingId: PRICING_EVENTS.PRICING_SHIFT_CASE_STUDY_LINK,
      'logo-src-small': {
        src: '/assets/img/logos/icon-logo-shift@2x.png',
        alt: 'logo of Shift',
      },
      stats: [
        {
          data: '60%',
          text:
            '60% increase in on-the-spot buyer conversions for non-financing customers, one month after implementation',
        },
      ],
    },
    {
      type: 'single-quote',
      'image-src-medium-up':
        '/assets/img/products/standalone/transactions/credibility-transactions-iphone-zeta@2x.png',
      'bg-color': 'bg-green',
      'quote-headline': 'Seamless integration and cost savings',
      'quote-text':
        'Partnering with Plaid has saved us hundreds of thousands of dollars and saved us limitless amounts of effort versus maintaining our own one-off relationships and integrations.',
      'quote-byline': 'Aditi Shekar, Founder of Zeta',
      'cta-text': 'View case study',
      'cta-link': '/documents/Zeta-Case-Study.pdf',
      trackingId: PRICING_EVENTS.PRICING_ZETA_CASE_STUDY_LINK,
      'logo-src-small': {
        src: '/assets/img/logos/icon-logo-zeta@2x.png',
        alt: 'Zeta logo',
      },
    },
  ],
};

export const accordionData = [
  {
    title: 'Can I use Plaid for free?',
    content:
      "Yes, Plaid's free Limited Production service allows you to make up to 200 API calls per product with live data. Limited Production is perfect for testing and learning more about the Plaid API.",
  },
  {
    title: 'Is Plaid free for consumers?',
    content:
      'We don’t charge consumers for our services. Rather, we  work with our fintech customers to securely connect your bank account to the apps you want in seconds.',
  },
  {
    title: 'How much does Plaid cost? What is the Plaid pricing model?',
    content: `Each Plaid product has its own pricing structure that is dependent on how the product works. For more details on our pricing models, see <a href='https://plaid.com/docs/account/billing/' data-instrumented=${PRICING_EVENTS.PRICING_FAQ_PLAID_BILLING_LINK}>Plaid Billing</a>.
    <br/><br/>
    Plaid uses three basic pricing models: one-time fee products, subscription fee products, and a  per-request fee structure.  Auth, Identity, and Income are one-time fee products, meaning you incur a charge only once for each connected account. The cost for one-time fee products is the same no matter how many API calls are made for the connected account.
    <br/><br/>
    For subscription products, every connected account incurs a monthly subscription fee. Other products have a per-request flat fee, meaning they incur a fee for each successful API call.
    <br/><br/>
    For more information on pricing or packages, or to explore pricing details based on volume discounts, contact our <a href='https://plaid.com/contact/' data-instrumented=${PRICING_EVENTS.PRICING_CONTACT_SALES_LINK}>Sales</a> team to learn more. `,
  },
  {
    title: 'How do I get billed for products?',
    content:
      "Pricing models vary across products. For more complete details on pricing models, see <a href='https://plaid.com/docs/account/billing' data-instrumented=${PRICING_EVENTS.PRICING_FAQ_PLAID_BILLING_LINK}>Plaid Billing</a>.",
  },
  {
    title: 'What discounts are available?',
    content: `Volume discounts are provided based on your contract duration and/or the minimum monthly commitment you are willing to make. For details, contact our <a href='https://plaid.com/contact/' data-instrumented=${PRICING_EVENTS.PRICING_FAQ_CONTACT_SALES_LINK}>Sales</a> team.`,
  },

  {
    title: 'Can I upgrade my plan later?',
    content:
      'Yes. At any point in time, you are allowed to upgrade to a plan that suits your needs.',
  },
  {
    title: 'How does Plaid Pay as you go pricing work?',
    content:
      "Pay as you go pricing at Plaid has no spending minimum. Simply sign up for the products you're looking to use in Plaid's production dashboard, and the card provided will be charged for the connected Items (accounts).",
  },
  {
    title: 'Does Plaid charge a fee?',
    content: `Plaid is free for consumers using a Plaid-powered app. Plaid does not charge consumers for using our services.
    <br/><br/>
      For Plaid customers, each product or API endpoint has a specific price structure. Contact our Sales team for information on volume discounts or Get Started today to learn more.`,
  },
];
