import { NAVIGATION_EVENTS } from 'src/lib/trackingEvents';

const {
  assets,
  auth,
  balance,
  identity,
  link,
  paymentInitiation,
  payouts,
  transactions,
  variableRecurringPayments,
  identityVerification,
  monitor,
} = {
  assets: {
    name: 'Assets',
    description: `Point-in-time snapshots of users' finances`,
    url: '/en-gb/products/assets',
    icon: '/assets/img/navbar/icon-assets-global.png',
  },
  auth: {
    name: 'Auth',
    description: 'Account number, sort code, IBAN, and BIC',
    url: '/en-gb/products/auth',
    icon: '/assets/img/navbar/icon-auth.png',
  },
  balance: {
    name: 'Balance',
    description: 'Real-time balance checks',
    url: '/en-gb/products/balance',
    icon: '/assets/img/navbar/icon-balance.png',
  },
  identity: {
    name: 'Identity',
    description: 'Bank account-holder information',
    url: '/en-gb/products/identity',
    icon: '/assets/img/navbar/icon-identity.png',
  },
  link: {
    name: 'Plaid Link',
    description:
      'Enable your users to instantly connect their financial accounts',
    url: '/en-gb/plaid-link',
    icon: '/assets/img/navbar/icon-plaid-link.png',
  },
  paymentInitiation: {
    name: 'Payment Initiation',
    description: 'Instant bank payments within your app',
    url: '/en-gb/products/payment-initiation',
    icon: '/assets/img/navbar/icon-payment-initiation.png',
  },
  payouts: {
    name: 'Payouts',
    description: 'Seamless payouts within your app',
    url: '/en-gb/products/payouts',
    icon: '/assets/img/navbar/icon-payouts.png',
  },

  transactions: {
    name: 'Transactions',
    description: '5+ years of categorised transaction data',
    url: '/en-gb/products/transactions',
    icon: '/assets/img/navbar/icon-transactions-global.png',
  },
  variableRecurringPayments: {
    name: 'Variable Recurring Payments',
    description: 'Enable your users to make flexible recurring payments',
    url: '/en-gb/products/variable-recurring-payments/',
    icon: '/assets/img/navbar/icon-variable-recurring-payments.png',
  },
  identityVerification: {
    name: 'Identity Verification',
    description: 'Global KYC and anti-fraud',
    url: '/en-gb/products/identity-verification/',
    icon: '/assets/img/navbar/icon-idverification-48.png',
  },
  monitor: {
    name: 'Monitor',
    description: 'AML and PEP screening',
    url: '/en-gb/products/monitor/',
    icon: '/assets/img/navbar/icon-monitor-48.png',
  },
};

const columns = [
  {
    gridClasses: 'small-4',
    items: [
      paymentInitiation,
      payouts,
      identityVerification,
      variableRecurringPayments,
    ],
  },
  {
    gridClasses: 'small-4',
    items: [transactions, balance, assets, monitor],
  },
  {
    gridClasses: 'small-4',
    items: [identity, auth, link],
  },
];

const products = {
  label: 'Products',
  megaMenuClass: 'products',
  submenuClass: 'cell small-9',
  subtitle: 'Learn how you can make the most of financial data',
  menuLinks: [
    {
      name: 'Global coverage',
      url: '/global',
    },
    {
      name: 'Pricing',
      url: '/pricing',
      trackingId: NAVIGATION_EVENTS.PRIMARY.USER_CLICKED_PRIMARY_NAV_PRICING,
    },
  ],
  submenu: {
    columns,
  },
};

export default products;
