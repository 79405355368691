import { useReducer } from 'react';
import { scroller } from 'react-scroll';

import { getStaticData } from 'src/lib/utils';

const Actions = Object.freeze({
  toggleFilter: 'TOGGLE_FILTER',
  resetFilters: 'RESET_FILTERS',
  toggleFeaturedCard: 'TOGGLE_FEATURED_CARD',
  filterStories: 'FILTER_STORIES',
  toggleDisabledFilters: 'TOGGLE_DISABLED_FILTERS',
});

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.toggleFilter: {
      const { filters } = state;
      return {
        ...state,
        filters: filters.map((filter) => {
          if (filter.label === action.label) {
            return {
              ...filter,
              active: !filter.active,
            };
          }
          return filter;
        }),
      };
    }
    case Actions.resetFilters: {
      const { filters } = state;
      return {
        ...state,
        filters: filters.map((filter) => ({ ...filter, active: false })),
      };
    }
    case Actions.toggleFeaturedCard: {
      const { filters } = state;
      const hasActiveFilters = filters.some((filter) => filter.active);
      return {
        ...state,
        showFeaturedCard: !hasActiveFilters,
      };
    }
    case Actions.filterStories: {
      const { stories, filters } = state;
      const activeFilters = filters
        .filter((filter) => filter.active)
        .map((filter) => filter.label);
      return {
        ...state,
        stories: stories.map((story) => {
          return {
            ...story,
            visible: activeFilters.every((filter) =>
              story.goals.includes(filter),
            ),
          };
        }),
      };
    }
    case Actions.toggleDisabledFilters: {
      const { stories, filters } = state;
      const relatedFilters = new Set();
      stories.forEach((story) => {
        if (story.visible) {
          story.goals.forEach((goal) => relatedFilters.add(goal));
        }
      });
      return {
        ...state,
        filters: filters.map((filter) => {
          return {
            ...filter,
            disabled: !relatedFilters.has(filter.label),
          };
        }),
      };
    }
    default:
      throw new Error(`${action.type} not a valid action`);
  }
};

// TODO: typescript this
const useCustomerStoryFilters = ({ locale, storiesData }) => {
  const { initialFilters } = getStaticData({
    locale,
    filePath: 'customer-stories/customer-stories-overview',
  });

  const [state, dispatch] = useReducer(reducer, {
    filters: initialFilters,
    stories: [...storiesData],
    showFeaturedCard: true,
  });

  const resetFilters = () => {
    dispatch({ type: Actions.resetFilters });
    dispatch({ type: Actions.toggleFeaturedCard });
    dispatch({ type: Actions.filterStories });
    dispatch({ type: Actions.toggleDisabledFilters });
    scrollTo('stories');
  };

  const toggleFilter = ({ active, label }) => {
    dispatch({ type: Actions.toggleFilter, label });
    dispatch({ type: Actions.toggleFeaturedCard });
    dispatch({ type: Actions.filterStories });
    dispatch({ type: Actions.toggleDisabledFilters });
    scrollTo('stories');
  };

  const scrollTo = (element) => {
    scroller.scrollTo(element, {
      duration: 500,
      delay: 0,
      smooth: 'smooth',
    });
  };

  return { state, resetFilters, toggleFilter };
};

export default useCustomerStoryFilters;
